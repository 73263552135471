import React from "react";

function Header() {
  return (
    <div className="header">
      <a href="/">Ben Kassman</a>
    </div>
  );
}

export default Header;
